
import LandingPage from '../landingpage/index.js'
import PrivacyPolicy from '../policies/Privacy.js'
import RefundPolicy from '../policies/RefundPolicy.js'
import TermsAndConditions from '../policies/TermsAndConditions.js'



const publicRoutes = [
  { path : "/", component: LandingPage},
  { path: "/privacy-policy", component: PrivacyPolicy},
  { path: "/refund-policy", component: RefundPolicy},
  { path: "/terms-of-service", component: TermsAndConditions },
  

]

export default publicRoutes
